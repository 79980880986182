<template>
<div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>
    </header>
    <!-- login section -->
    <ForgotPasswordSection></ForgotPasswordSection>
    <!-- Blog  -->
    <Footer classname="bg-cus on-dark"></Footer>
</div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import ForgotPasswordSection from '@/components/n1/ForgotPasswordSection'
import Footer from "@/pages/n1/Footer"
import HeaderMain from "@/components/n1/HeaderMain"


export default {
  name: 'Login',
  components: {
    ForgotPasswordSection,
    Footer,
    HeaderMain
  },
  data () {
    return {
      SectionData,

    }
  }
}
</script>

<style scoped>
.bg-cus {
  background-color: #ffe5d0;
}
</style>
