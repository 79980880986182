import Axios from "../axios";
import { toForm, outErr } from "./apiConfig";
const url = "/api/pwd"

export default {
    retrieve(data, out) {
        Axios.post(url + "/retrieve", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    modify(data, out) {
        Axios.post(url + "/modification", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }

}