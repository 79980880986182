import Axios from "../axios";
import { toForm, outErr } from "./apiConfig";
const url = "/api/email"

export default {
    code(data, out) {
        Axios.post(url + "/code", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    active(data, out) {
        Axios.post(url + "/activation", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    email(email, out) {
        Axios.get(url + "/validation", { email }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}