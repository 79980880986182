<template>
  <section class="login-section section-space-b pt-4 pt-md-5 mt-md-3 main-content">
    <div class="container">
      <div class="row align-items-center justify-content-center" v-loading="loading">
        <div class="col-lg-6 mb-5 mb-lg-0 d-none d-lg-block">
          <img :src="require('@/images/tmp/sign-up.jpg')" alt="" class="img-fluid">
        </div><!-- end col-lg-6 -->
        <div class="col-lg-6">
          <div class="section-head-sm">
            <h2 class="mb-1">Forgot Password</h2>
            <p>Please enter your email address. You will receive your
              verify code information through email.</p>
          </div>
          <div class="form">
            <div class="form-floating mb-4">
              <input type="email" ref="email" class="form-control " placeholder="Email" v-model="data.email"
                     @change="emailCheck()" @focus="inputfocus($event)">
              <label for="emailAddress">Email</label>
              <div class="invalid-feedback">
                {{ emailErrorText }}
                <!--                              Your email cannot be empty.-->
                <!--                              -->
              </div>

            </div><!-- end form-floating -->


            <div class="d-flex align-items-center mb-4">
              <div class="form-floating " style="flex: 1;width: 0">
                <input type="text" ref="verificationCode" class="form-control " placeholder="Verify Code "
                       v-model="data.verificationCode" @focus="inputfocus($event) " @input="validateNumberInput">
                <label for="emailAddress">Verify Code </label>
                <div class="invalid-feedback">
                  Your Verify Code cannot be empty.
                </div>

              </div><!-- end form-floating -->
              <div class="ms-3" style="height: 100%">
                <button class="sign-in btn btn-dark" v-if="codeTime === 0" @click="code">
                  Send
                </button>
                <button class="btn btn-dark disabled" disabled v-else>
                  {{ codeTime }}S
                </button>
              </div>
            </div>


            <div class="form-floating mb-4">

              <input type="password" ref="newPassword" class="form-control   password" id="newPassword"
                     placeholder="newPassword" v-model="data.newPassword" @focus="inputfocus($event)">
              <label for="newPassword">New Password</label>
              <a href="password" class="password-toggle" title="Toggle show/hide pasword">
                <em class="password-shown ni ni-eye-off"></em>
                <em class="password-hidden ni ni-eye"></em>
              </a>
              <div class="invalid-feedback">
                {{ passwordText }}
              </div>
            </div><!-- end form-floating -->


<!--            <div class="col-lg-12" style="margin-bottom: 10px;" v-if="alert.show">-->
<!--              <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>-->
<!--            </div>-->


            <button class="sign-in btn btn-dark w-100" type="button" @click="changePassword()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
              Submit
            </button>
            <!--                            <span class="d-block my-4">— or login with —</span>-->
            <!--                            <ul class="btns-group d-flex">-->
            <!--                                <li class="flex-grow-1" v-for="(list, i) in loginData.btns" :key="i"><router-link :to="list.path" class="btn d-block" :class="list.btnClass"><em class="ni" :class="list.icon"></em> {{ list.title }} </router-link></li>-->
            <!--                            </ul>-->
            <p class="mt-3 form-text">Remember your password?
              <router-link to="/sign-in" class="btn-link">Sign in</router-link>
            </p>


            <!--                          <p class="mt-3  text-center">-->
            <!--                            <router-link :to="loginData.btnTextLink" class="form-text">-->
            <!--                              {{ loginData.haveAccountText }}-->
            <!--                            </router-link>-->

            <!--                          </p>-->

          </div>
        </div><!-- end col-lg-6 -->
      </div><!-- end row -->
    </div><!-- end container -->
  </section>
</template>
<script>
import emailApi from "@/apis/emailApi"
import pwdApi from "@/apis/pwdApi";

export default {
  name: 'LoginSection',
  data() {
    return {
      data: {
        email: "",
        verificationCode: "",
        newPassword: ""
      },
      codeTime: 0,
      alert: {
        show: false,
        text: ""
      },
      email: true,
      passwordText: '',
      loading: false,
      emailErrorText: ''
    }
  },
  mounted() {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach(item => {
          item.addEventListener("click", function (e) {
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href"));
            if (target.type == "password") {
              target.type = "text";
              item.classList.add("is-shown");
            } else {
              target.type = "password";
              item.classList.remove("is-shown");
            }
          });

        });
      }
    }

    showHidePassword(".password-toggle");


  },
  methods: {

    code() {
      if (this.data.email == "") {
        this.alert.show = true
        this.alert.text = "Your email cannot be empty"
        this.emailErrorText = 'Your email cannot be empty'
        this.$refs['email'].classList.add('is-invalid')
      } else {
        // eslint-disable-next-line no-undef
        emailApi.email(this.data.email, res => {
          if (res) {
            //获取按钮禁用时间
            this.codeTime = 60
            const timer = setInterval(() => {
              this.codeTime--
              if (this.codeTime == 0) {
                clearInterval(timer)
              }
            }, 1000)
            emailApi.code({
              email: this.data.email
            }, res2 => {
              if (res2.code != 200) {
                this.alert.show = true
                //错误返回信息
                this.alert.text = res2.msg
                this.emailErrorText = res2.msg
                this.$refs['email'].classList.add('is-invalid')
              }
            })
          }
        })
      }
    },
    changePassword() {
      // const passwordRegex =/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-])[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;
      if (this.data.verificationCode == "" && this.data.email == "" && this.data.newPassword == "") {
        this.alert.show = true
        this.alert.text = "Please fill out the form"
        return
      }
      if (!passwordRegex.test(this.data.newPassword)) {
        this.alert.show = true
        this.alert.text = "Password must be a minimum of 8 characters and include at least one letter and one number."
        this.passwordText = this.alert.text
        this.$refs['newPassword'].classList.add('is-invalid')

        return
      }
      let data = JSON.parse(JSON.stringify(this.data))
      data.newPassword = btoa(this.data.newPassword)
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Changing password...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.loading = true;
      // eslint-disable-next-line no-undef
      pwdApi.retrieve(data, res => {
        // loading.close()
        this.loading = false;
        if (res.code != 200) {
          this.alert.show = true
          //错误返回信息
          this.alert.text = res.msg
        } else {
          //修改成并返回登录页面
          this.$message.success("success")
          this.$router.push("/sign-in")
        }
      })
    },
    emailCheck() {
      // alert(this.data.email)
      if (this.data.email === "") {
        return
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Checking email...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.loading = true;
      emailApi.email(this.data.email, res => {
        // loading.close()
        this.loading = false;
        if (!res) {
          this.alert.show = true
          this.alert.text = "Email is incorrect"
          this.emailErrorText = this.alert.text
          this.$refs['email'].classList.add('is-invalid')
        }
      })
    },

    inputfocus(e) {
      console.log(e)
      e.srcElement.classList.remove('is-invalid');
      this.alert.show = false;
    },
    validateNumberInput() {
      let userInput = this.data.verificationCode;
      if (!/^\d+$/.test(userInput)) {
        this.data.verificationCode = userInput.replace(/\D/g, '');
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.sign-in{
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width:100%;
  &:hover{
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
}


::v-deep a {
  //color: rgba(12,102,255,0.8);
  //color: #1D2C48;
  //  color: #0C66FF;
  //}
}



.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}


.form-floating {
  .invalid-feedback {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  .form-control {

    &.password {
      &.is-invalid {
        margin-bottom: 3.5rem;
        //padding-right: calc(3.5em + 0.75rem);
        //background-position: right calc(2.8em + 0.1875rem) center;
        //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

    }

    &.is-invalid {
      margin-bottom: 1.65rem;
      background-image: none;
      border-color: var(--bs-form-invalid-border-color);
      //padding-right: calc(1.5em + 0.75rem);
      & ~ .invalid-feedback {
        display: block;
      }
    }

  }


  //background-repeat: no-repeat;
  //background-position: right calc(0.375em + 0.1875rem) center;
  //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
